import React from "react";
import Navbar from "./Navbar";
import Content from "./Content";

import { motion } from "framer-motion";

const Hero = () => {
  return (
    <div>
      <section className="h-hero bg-blue-50">
        <Navbar />
        <Content />
      </section>
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        initial={{ opacity: 0, y: 170 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 1.2,
          type: "spring",
          stiffness: "200",
          delay: 2.4,
        }}
      >
        <path
          fill="#eff6ff"
          fill-opacity="1"
          d="M0,160L205.7,128L411.4,224L617.1,32L822.9,192L1028.6,160L1234.3,288L1440,192L1440,0L1234.3,0L1028.6,0L822.9,0L617.1,0L411.4,0L205.7,0L0,0Z"
        ></path>
      </motion.svg>{" "}
    </div>
  );
};

export default Hero;

// <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#eff6ff" fill-opacity="1" d="M0,160L360,64L720,224L1080,128L1440,192L1440,0L1080,0L720,0L360,0L0,0Z"></path></svg>
