import React from "react";

const TermsPolicies = () => {
  return (
    <>
      <div className="m-auto max-w-7xl flow-content py-10">
        <h3 className="text-2xl">Terms of Use (“Terms”)</h3>
        <p>Effective Date: July 6, 2021</p>
        <p>
          Thank you for wishing to use any of the services provided by Axeite
          Media LLC (“us”, “we”, “our”). We are available via the{" "}
          {`bigoloo.com`} website (the “Site”), or via the Chrome extension (the
          “Extension”). The Site, the Extension, and any other services provided
          with respect to the Site or the Extension are collectively referred to
          as the “Service”.
        </p>
        <p>
          Please read these Terms of Use (“Terms”) carefully before using the
          Service. Your access to and use of the Service is conditioned on your
          acceptance of and compliance with these Terms. These Terms apply to
          all visitors, users, and anyone who accesses or uses the Service.
        </p>
        <p>
          By accessing or using the Service, you agree to be bound by these
          Terms. If you do not agree to these Terms, do not access or use the
          Service. As long as you do not cease your use of the Service, you will
          be conclusively deemed to have read and accepted these Terms.
        </p>
        <h4 className="text-xl">License</h4>
        <p>
          {`Axeite Media LLC`} tab grants you a revocable, non-exclusive,
          non-transferable, limited license to download, install and use the
          Application solely for your personal, non-commercial purposes strictly
          in accordance with the terms of this Agreement.
        </p>
        <h4 className="text-xl">Termination</h4>
        <p>
          We may terminate or suspend access to the Service at any time, without
          prior notice or liability, for any reason or no reason at all,
          including without limitation if you breach the Terms.
        </p>
        <p>
          All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity, and limitations
          of liability.
        </p>
        <h4 className="text-xl">Links to other Web Sites</h4>
        <p>
          The Site may contain links to third-party websites, applications, or
          services. The Site has no control or ownership over the content,
          privacy policies, or practices of such sites, applications, or
          services. You agree that the Site is not responsible for the
          availability of such resources.
        </p>
        <p>
          You further agree and acknowledge that the Site shall not be
          responsible or liable, directly or indirectly, for any damage or loss
          caused or alleged to be caused by or in connection with your use of or
          reliance on any such content, goods, or services available on or
          through any such websites or services.
        </p>
        <h4 className="text-xl">Governing Law</h4>
        <p>
          Our failure to enforce any right or provision of these Terms will not
          be considered a waiver of those rights. If any provision of these
          Terms is held to be invalid or unenforceable by a court, the remaining
          provisions of these Terms will remain in effect. These Terms
          constitute the entire agreement between us regarding our Service, and
          supersede and replace any prior agreements we might have between us
          regarding the Service.
        </p>
        <h4 className="text-xl">Changes</h4>
        <p>
          The Site reserves the right, at our sole discretion, to modify or
          replace these Terms at any time. If a revision is a material we will
          provide at least 30 days’ notice prior to any new terms taking effect.
          What constitutes a material change will be determined at our sole
          discretion.
        </p>
        <p>
          By continuing to use or access the Service after those revisions
          become effective, you agree to be bound by the revised terms. If you
          do not agree to the new terms, you can discontinue the use of the
          Service.
        </p>
        <h4 className="text-xl">Minors</h4>
        <p>
          By using the Service, you affirm that you are over the age of 16 in
          the EEA or 13 in the U.S.A (“Child”), or possess legal parental or
          guardian consent, and are fully able to enter into the terms and
          conditions set forth in these Terms and to abide and comply with these
          Terms.
        </p>
        <h4 className="text-xl">Privacy Policy</h4>
        <p>
          The Site’s Privacy Policy describes the ways the Site collects, uses,
          stores and discloses your personal information, and is hereby
          incorporated by this reference to these Terms.
        </p>
        <h4 className="text-xl">About Us</h4>
        <p>
          Axeite is the platform that propels real connections.
          It’s the place that drives your target audience to recognize and remember your brand.
          The network to maximize your ad revenue. The perfect balance between creativity, engagement, and real revenue growth.
        </p>
        <p>
          The extension contains affiliate links. If you choose to click on a tile link, we will be using a third party link for this service.
        </p>
        <h4 className="text-xl">Contact Us</h4>
        <p>
          If you have any questions about these Terms, please contact us at{" "}
          {`me@axeite.com`}.
          Address: White Clay Center Dr, Newark, DE 19711, US
        </p>
      </div>
    </>
  );
};

export default TermsPolicies;
