import React from "react";
import { motion } from "framer-motion";
import LaptopMan from "../image/laptop-man.jpg";

// import { ProductClass } from '../prod.js'
import { ProductClass } from '../common/prod.js'



const Content = () => {
  return (
    <div className=" grid grid-cols-2 max-w-6xl h-96  m-auto pl-8 pr-8 pt-16 font-Montserrat">
      <div className=" col-span-1 flex items-center">
        <motion.div className="text-left -mt-10">
          <motion.h1
            className="text-5xl font-bold block w-full col-span-full"
            initial={{ opacity: 0, y: -200 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 1.2,
              type: "spring",
              stiffness: "150",
              delay: 0,
            }}
          >
            Bigoloo
          </motion.h1>
          <motion.p
            className="block col-span-full pr-20 pt-6 pb-6 tracking-wide"
            initial={{ opacity: 0, y: -150 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 1.2,
              type: "spring",
              stiffness: "150",
              delay: 0.8,
            }}
          >
            Get top trending chill remixes on the internet right now in a neat
            new-tab playlist for your browser
          </motion.p>
          <motion.button
            class=" font-Montserrat uppercase tracking-wide rounded-full bg-primary  text-lg hover:bg-secondary  font-medium text-white  py-4 px-12  transition duration-300 ease-out"
            initial={{ opacity: 0, x: -170 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{
              duration: 1.2,
              type: "spring",
              stiffness: "200",
              delay: 1.6,
            }}
            onClick={()=>{
              const dic = {"cid": "ttt", "fid":"inc", "channel":"tcc","pclkid":"uv"}
              const extMap = {"chrome":"kokbcalgclegpbkhggkmjehhbmpdlaoo", "edge": "kokbcalgclegpbkhggkmjehhbmpdlaoo"}
              const ev_engine = "https://trk.bigoloo.com/c/?at=install"
              const p = new ProductClass(extMap, dic, ev_engine)
              p.Install()
            }}
          >
            Get It Now
          </motion.button>
        </motion.div>
      </div>
      <div className=" col-span-1 flex justify-center text-left items-center">
        <img
          src={LaptopMan}
          width="400px"
          alt="person with laptop"
          style={{ opacity: 0.8 }}
        />
      </div>
    </div>
  );
};

export default Content;

// <img src="https://www.pinclipart.com/picdir/big/11-117837_samsung-mobile-phone-clipart-hand-png-mobile-in.png" width='200px' alt="" />
