import React from "react";

const Description = () => {
  return (
    <section className="w-full pt-10 pb-44" id="about">
      <article className="max-w-6xl pl-8 pr-8 m-auto grid grid-cols-3 gap-8">
        <div className="col-span-1 p-3 text-left">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#7357f6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"
            />
          </svg>
          <h3 className="pt-3 pb-3 font-semibold text-lg">
            Wide music sites to choose from
          </h3>
          <p className="text-gray-600">
            We support the world’s most popular and applauded music sites for
            your music search
          </p>
          <ul className="text-gray-600">
            <li className=" pt-2">&bull; Youtube</li>
            <li className=" pt-2">&bull; Spotify</li>
            <li className=" pt-2">&bull; Apple Music</li>
          </ul>
        </div>
        <div className="col-span-1 p-3 text-left">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#7357f6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <h3 className="pt-3 pb-3 font-semibold text-lg">
            Support of search engines
          </h3>
          <p className="text-gray-600">
            Use Bigoloo’s powerful search bar to get results from top search
            engines such as Google, Bing, and Yahoo
          </p>
        </div>
        <div className="col-span-1 p-3 text-left">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#7357f6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
          <h3 className="pt-3 pb-3 font-semibold text-lg">
            Beautiful and Changing Backgrounds
          </h3>
          <p className="text-gray-600">
            Powered by Bing to display awesome background poster related to the
            music industry every time you open a new tab
          </p>
        </div>
      </article>
    </section>
  );
};

export default Description;
