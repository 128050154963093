import "./App.css";
import Home from "./pages/Home";
import { Routes, Route } from "react-router-dom";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import NoRoute from "./pages/NoRoute";
import Uninstall from "./pages/Uninstall";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacy" exact element={<Privacy />} />
      <Route path="/terms" exact element={<Terms />} />
      <Route path="/uninstall" exact element={<Uninstall />} />
      <Route path="*" element={<NoRoute />} />
    </Routes>
  );
}

export default App;
