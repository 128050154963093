import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import TermsPolicies from "../components/TermsPolicies";
const Terms = () => {
  return (
    <>
      <Navbar />
      <TermsPolicies />
      <Footer />
    </>
  );
};

export default Terms;
