import React from "react";
import Navbar from "../components/Navbar";
import Panda from '../image/panda.svg';
import { useLocation } from "react-router-dom";
import p from "../constants/prod";



const Uninstall = () => {
  const location = useLocation();
  React.useEffect(() => {
    if (location.pathname === "/uninstall") {
      p.Uninstall();
    }
  }, [location]);
  return (
    <div className="">
      <Navbar />
      <div className="flex center-it pl-5 pr-5 py-2 font-medium font-Montserrat full">
        <div className="direction forms">
          <h1>We are sad to see you leave</h1>
          <hr className="stylized-hr" />
          <h1>We hope to see you again soon!</h1>
        </div>
        <div className="forms">
          <form action="/form/submit" method="POST" className="">
            <textarea className="comment" placeholder="Let us know why you decided to uninstall bigoloo ?"></textarea>
            <br />
            <div className="flex gapy">
              <input type="submit" name="submit" value="Send" className="btn-send" />
              <input type="reset" name="cancel" value="Cancel" className="btn-cancel" />
            </div>
          </form>
        </div>
      </div>
      <div className="panda">
        <div className="background-blue-panda">
          <img src={Panda} alt="" className="absolute panda-absolute" />
        </div>
      </div>
    </div>
  );
};

export default Uninstall;
