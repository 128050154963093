import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import PrivacyPolicies from "../components/PrivacyPolicies";

const Privacy = () => (
  <>
    <Navbar />
    <PrivacyPolicies />
    <Footer />
  </>
);

export default Privacy;
