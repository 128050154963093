import React from "react";

import logo from "../image/bigoloo.png";

const Navbar = () => {
  return (
    <div className="w-full font-Montserrat">
      <div className="flex justify-between  m-auto pl-5 pr-5 py-2 max-w-6xl font-medium">
        <div>
          <a href="/">
            <img src={logo} alt="bigoloo logo" width="150" />
          </a>
        </div>
        <nav className="flex justify-center items-center">
          <ul className="flex uppercase ">
            <li className="pl-4 pr-4 cursor-pointer">
              <a
                href="/terms"
                className="hover:text-primary transition ease-out duration-300"
              >
                Terms
              </a>
            </li>
            <li className="pl-4 pr-4 cursor-pointer">
              <a
                href="/privacy"
                className="hover:text-primary transition ease-out duration-300"
              >
                Privacy
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
