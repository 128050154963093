import React from "react";

const Blocks = () => {
  return (
    <div className="w-full pt-28 pb-36 bg-gradient-to-tr from-start to-end font-Montserrat" id="faq">
      <div className="max-w-3xl pl-5 pr-5 text-center text-white m-auto tracking-wide">
        <h2 className="font-medium text-4xl">Frequently Asked questions</h2>
      </div>
      <div className="max-w-2xl  mt-10 m-auto flex flex-wrap text-white ">
        <ul className="qa">
          <li className="flex">
            <span className="q">Q.</span>
            <p className="question">Why do people use this extension?</p>
          </li>
          <li className="flex">
            <span className="a">A.</span>
            <p className="answer">
              We provide an easier and more convenient way to listen to your
              favorite music.
            </p>
          </li>
        </ul>
        <ul className="qa">
          <li className="flex">
            <span className="q">Q.</span>
            <p className="question">Is the extension free?</p>
          </li>
          <li className="flex">
            <span className="a">A.</span>
            <p className="answer">
              Yes, it is a free extension in the Chrome Web Store.
            </p>
          </li>
        </ul>
        <ul className="qa">
          <li className="flex">
            <span className="q">Q.</span>
            <p className="question">Can I change my default search engine?</p>
          </li>
          <li className="flex">
            <span className="a">A.</span>
            <p className="answer">
              We use Bing as a default search engine. If you want to use a
              different one, you can go to Settings and change it.
            </p>
          </li>
        </ul>
        <ul className="qa">
          <li className="flex">
            <span className="q">Q.</span>
            <p className="question">Can I disable background posters?</p>
          </li>
          <li className="flex">
            <span className="a">A.</span>
            <p className="answer">
              Yes, you can enable/disable the background posters on the Settings
              page.
            </p>
          </li>
        </ul>
        <ul className="text-left py-5">
          <li className="flex">
            <span className="q">Q.</span>
            <p className="question">Is my search history available?</p>
          </li>
          <li className="flex">
            <span className="a">A.</span>
            <p className="answer">
              Yes, you can visit the search history by default. If you do not
              want to maintain history, please go to the Settings to change it.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Blocks;

// icon blocks
// <div className="max-w-5xl  mt-20 m-auto flex flex-wrap text-white bg-blue-500">
// <div className="border-r border-b w-1/4 p-6 ">
//   <div className="flex justify-center">
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       className="h-10 w-10"
//       fill="none"
//       viewBox="0 0 24 24"
//       stroke="currentColor"
//     >
//       <path d="M12 14l9-5-9-5-9 5 9 5z" />
//       <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
//       <path
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         strokeWidth={2}
//         d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
//       />
//     </svg>
//   </div>
//   <h2 className="text-5xl tracking-wider font-bold py-3">500</h2>
//   <p>User groups</p>
// </div>
// <div className="border-r border-b w-1/4 p-6">
//   <div className="flex justify-center">
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       className="h-10 w-10"
//       fill="none"
//       viewBox="0 0 24 24"
//       stroke="currentColor"
//     >
//       <path
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         strokeWidth={2}
//         d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129"
//       />
//     </svg>
//   </div>
//   <h2 className="text-5xl tracking-wider font-bold py-3">7K</h2>
//   <p>Languages</p>
// </div>
// <div className="border-r border-b w-1/4 p-6">
//   <div className="flex justify-center">
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       className="h-10 w-10"
//       fill="none"
//       viewBox="0 0 24 24"
//       stroke="currentColor"
//     >
//       <path
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         strokeWidth={2}
//         d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
//       />
//     </svg>
//   </div>
//   <h2 className="text-5xl tracking-wider font-bold py-3">20M</h2>
//   <p>user groups</p>
// </div>
// <div className="border-b w-1/4 p-6">
//   <div className="flex justify-center">
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       className="h-10 w-10"
//       fill="none"
//       viewBox="0 0 24 24"
//       stroke="currentColor"
//     >
//       <path
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         strokeWidth={2}
//         d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
//       />
//     </svg>
//   </div>
//   <h2 className="text-5xl tracking-wider font-bold py-3">500</h2>
//   <p>user groups</p>
// </div>
// <div className="border-r  w-1/4 p-6">
//   <div className="flex justify-center">
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       className="h-10 w-10"
//       fill="none"
//       viewBox="0 0 24 24"
//       stroke="currentColor"
//     >
//       <path
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         strokeWidth={2}
//         d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
//       />
//     </svg>
//   </div>
//   <h2 className="text-5xl tracking-wider font-bold py-3">10</h2>
//   <p>Admins</p>
// </div>
// <div className="border-r  w-1/4 p-6">
//   <div className="flex justify-center">
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       className="h-10 w-10"
//       fill="none"
//       viewBox="0 0 24 24"
//       stroke="currentColor"
//     >
//       <path
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         strokeWidth={2}
//         d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
//       />
//     </svg>
//   </div>
//   <h2 className="text-5xl tracking-wider font-bold py-3">500</h2>
//   <p>Groups</p>
// </div>
// <div className="border-r w-1/4 p-6">
//   <div className="flex justify-center">
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       className="h-10 w-10"
//       fill="none"
//       viewBox="0 0 24 24"
//       stroke="currentColor"
//     >
//       <path
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         strokeWidth={2}
//         d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
//       />
//     </svg>
//   </div>
//   <h2 className="text-5xl tracking-wider font-bold py-3">500</h2>
//   <p>user groups</p>
// </div>
// <div className="w-1/4 p-6">
//   <div className="flex justify-center">
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       className="h-10 w-10"
//       fill="none"
//       viewBox="0 0 24 24"
//       stroke="currentColor"
//     >
//       <path
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         strokeWidth={2}
//         d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
//       />
//     </svg>
//   </div>
//   <h2 className="text-5xl tracking-wider font-bold py-3">500</h2>
//   <p>user groups</p>
// </div>
// </div>
