import { ProductClass } from "../common/prod";

const dic = { cid: "i", fid: "f", channel: "c", pclkid: "clk" };
const extMap = {
  chrome: "kokbcalgclegpbkhggkmjehhbmpdlaoo",
  edge: "kokbcalgclegpbkhggkmjehhbmpdlaoo",
};
const ev_engine = "https://trk.bigoloo.com/c/";
const domain = "bigoloo.com";
const p = new ProductClass(extMap, dic, ev_engine, domain);

export default p;
