import React from "react";

import logo from "../image/bigoloo.png";

const Footer = () => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#eff6ff"
          fill-opacity="1"
          d="M0,32L360,192L720,96L1080,256L1440,96L1440,320L1080,320L720,320L360,320L0,320Z"
        ></path>
      </svg>

      <div className="w-full bg-blue-50 font-Montserrat">
        <div className="max-w-5xl px-5 flex justify-between m-auto pb-20 text-gray-700">
          <div>
            <h5 className="uppercase font-semibold text-left border-b border-gray-400">
              Useful links{" "}
            </h5>
            <ul className="text-left pt-3 font-medium">
              <a href="/privacy">
                <li className="footerlink">Privacy Policy</li>
              </a>
              <a href="/terms">
                {" "}
                <li className="footerlink">Terms & Conditions</li>
              </a>
            </ul>
          </div>
          <div>
            <img src={logo} alt="bigoloo logo" width="200" />
          </div>
          <div className="text-right">
            <p className="font-medium">© Copyright 2021</p>
            <p className="text-sm pt-2">All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

// <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#eff6ff" fill-opacity="1" d="M0,32L360,192L720,96L1080,256L1440,96L1440,320L1080,320L720,320L360,320L0,320Z"></path></svg>
