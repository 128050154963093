import React from "react";
import Hero from "../components/Hero";
import Description from "../components/Description";
import Blocks from "../components/Blocks";
import Quote from "../components/Quote";
import Footer from "../components/Footer";
const Home = () => {
  return (
    <div className="App">
      <Hero />
      <Description />
      <Blocks />
      <Quote />
      <Footer />
    </div>
  );
};

export default Home;
