import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
const NoRoute = () => {
  return (
    <>
      <Navbar />
      <div className="m-auto max-w-7xl flow-content py-10">
        <h1 className="text-5xl">404</h1>
        <h2 className="text-3xl">
          The page you are looking for does not exist
        </h2>
      </div>
      <Footer />
    </>
  );
};

export default NoRoute;
