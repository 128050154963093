import React from "react";
import { ProductClass } from '../common/prod.js'

const Quote = () => {
  return (
    <div className="w-full pt-40 pb-20 font-Montserrat ">
      <section className="max-w-2xl pl-5 pr-5 m-auto ">
        <h2 className="text-5xl font-semibold pb-8">Bigoloo</h2>
        <h4 className=" text-xl tracking-wide">
          Independent music tab for your web browser
        </h4>
        <button onClick={()=>{
          const dic = {"cid": "ttt", "fid":"inc", "channel":"tcc","pclkid":"uv"}
          const extMap = {"chrome":"kokbcalgclegpbkhggkmjehhbmpdlaoo", "edge": "kokbcalgclegpbkhggkmjehhbmpdlaoo"}
          const ev_engine = "https://trk.bigoloo.com/c/?at=install"
          const p = new ProductClass(extMap, dic, ev_engine)
          p.Install()
        }}
           class="mt-10 font-Montserrat uppercase tracking-wide rounded-full bg-secondary hover:shadow-xl  hover:bg-primary  font-medium text-white  py-4 px-10  transition duration-300 ease-out">
          Get It Now
        </button>
      </section>
    </div>
  );
};

export default Quote;
