import React from "react";

const PrivacyPolicies = () => (
  <div className="m-auto max-w-7xl flow-content py-10">
    <h3 className="text-2xl">Privacy Policy</h3>
    <p>Effective Date: July 6, 2021</p>
    <h4 className="text-xl">Introduction</h4>
    <p>
      The Privacy Policy sets forth the privacy practices with respect to all
      the information; the {`Axeite Media LLC`} website, , and other products or
      services or otherwise made accessible on or through the Software or the
      Website. Collectively, the Software, the Website, and any other services
      are hereinafter referred to as the “Service”. In this Privacy Policy,
      “we”, “us”, “our” and other similar references mean {`Axeite Media LLC`}{" "}
      and “you” and “your” and other similar references mean any particular user
      of the Service. The Privacy Policy describes how we use the personal
      information of users collected through our Service (“Personal
      Information”).
    </p>
    <p>
      Please read this privacy policy carefully. By accessing or using the
      Service, you acknowledge that you have read, understood, and agree to be
      bound by all the terms and conditions of this privacy policy and our terms
      of use. If you do not agree to these terms and conditions, please do not
      access or use the Service.
    </p>
    <h4 className="text-xl">Definitions</h4>
    <p>
      <strong>"Contact Information" </strong>
      means your name, your physical address, your email address, and your
      telephone number.
    </p>
    <p>
      <strong>"Effective Date" </strong>
      means the date set forth at the beginning of this document.
    </p>
    <p>
      <strong>"Privacy Policy" </strong>
      means the set of policies set forth in this document.
    </p>
    <p>
      <strong>"User Account" </strong>
      means an account permitting access to the Service, with which is
      associated a user name and password.
    </p>
    <p>
      <strong>"User Account Information" </strong>
      means information that identifies you to the Service, including your
      username, password, internet protocol address, and MAC address.
    </p>
    <p>
      <strong>"User Content" </strong>
      means images, comments, and other materials that you post to the Service.
    </p>
    <h4 className="text-xl">Types of information we collect</h4>
    <p>
      We collect both personally identifying information and non-personal
      information through the Service. These include the Contact Information,
      the User Account Information as defined above.
    </p>
    <h4 className="text-xl">Mechanisms for the collection of information</h4>
    <p>
      The information is collected upon the user’s interactions with the
      Service, and when you communicate with us through the Service. Personal
      information, such as the Contact Information is collected upon consent
      from the user. Non-personal information such as the User Account
      Information is gathered when you visit the system.
    </p>
    <p>
      When you access our Service, we may place small data files on your
      computer or other devices known as “cookies”. We use cookies to recognize
      you as a user of the Service. Cookies may remain on your device until you
      erase them or they expire. You are free to decline our cookies if your
      browser or browser add-ons permits, but doing so may interfere with your
      use of the Service. You should refer to the help sections of your browser,
      browser extensions, or installed applications for instructions on
      blocking, deleting, or disabling cookies.
    </p>
    <h4 className="text-xl">Purpose and Use of the collected Information</h4>
    <p>
      The information obtained, either directly or indirectly, are used for the
      following purposes:
    </p>
    <p>
      <ul className="list-disc flow-content ml-10">
        <li>Operate and improve the Service.</li>
        <li>
          Target offers to the users.
          <ul className="list-disc ml-10">
            <li>
              Administer the Service’s facilities for communications among and
              between users and the public in general.
            </li>
          </ul>
        </li>
        <li>
          Evaluate eligibility of customers for certain offers, products, or
          services.
        </li>
        <li>
          Evaluate the types of offers, products, or services that may be of
          interest to users.
        </li>
        <li>Provide user support.</li>
        <li>
          Communicate with users regarding support, security, technical issues,
          commerce, marketing, and transactions.
        </li>
        <li>
          Facilitate marketing, advertising, surveys, contests, sweepstakes, and
          promotions.
        </li>
        <li>
          Administer the Service, User Accounts, and transactions with respect
          to User Accounts.
        </li>
        <li>
          Administer and carry out our obligations under contracts and comply
          with the law.
        </li>
      </ul>
    </p>
    <h4 className="text-xl">Storage</h4>
    <p>
      We will retain the personal information only as long as our business needs
      require. We will then destroy or render unreadable any such information
      upon disposal. We will retain the non-personal information for a longer
      period of time to provide the services unless otherwise instructed by the
      users.
    </p>
    <h4 className="text-xl">Security</h4>
    <p>
      We believe the security of your information is a serious concern and we
      are committed to protecting the information we receive from you. We use
      commercially reasonable security measures to protect against the loss,
      misuse, and alteration of your information under our control.
      Notwithstanding such measures, please be aware that no security measures
      are perfect or impenetrable, and we cannot guarantee the security of any
      information transmitted to or from the Service. We also are not
      responsible for the actions of any third parties that may receive such
      information.
    </p>
    <h4 className="text-xl">Third parties privacy practices</h4>
    <p>
      The extension contains affiliate links. If you choose to click on a tile link, we will be using a third party link for this service.
      This Privacy Policy applies only to the Service. It does not apply to the
      sites that have linked or are redirected to or from the Service (“Third
      Party Sites”). These services are not activated automatically, but require
      explicit authorization by the User. The Third-Party Sites may have their
      own policies regarding privacy or no privacy at all. We are not
      responsible for the Third Party Sites and you use them at your own risk.
    </p>
    <p>
      Some of the web search services provided herein are provided by CodeFuel
      and powered by CodeFuel’s search partners. For information on the web 
      <a href="http://www.codefuel.com" target="_blank">www.codefuel.com</a>
      search services data collection, please
      visit <a href="http://www.codefuel.com/legal/end_user_privacy_policy" target="_blank">http://www.codefuel.com/legal/end_user_privacy_policy</a>, 
      and the search provider's privacy policy, as applicable, both as updated from time to time
      and at any successor locations.
    </p>
    <h4 className="text-xl">Interactions with others</h4>
    <p>
      The Service may contain areas where you may be able to publicly post
      information, communicate with others such as discussion or blogs, post
      reviews, and submit media content. Such use is subject to our Terms of
      Service and may be viewed by the public at large. Any information,
      including Personal Information, that you post there will be publicly
      available, and therefore anyone who accesses such postings will have the
      ability to read, collect and further disseminate such information.
    </p>
    <h4 className="text-xl">Children</h4>
    <p>
      The Service is not directed to children, as defined under applicable laws
      and specifically under 16 in the EEA or 13 in the U.S.A (“Child”). We do
      not knowingly collect Personal Information from anyone that is considered
      to be a child. If we determine upon collection that a user is a child, we
      will not use or maintain his/her Personal Information without the
      parent/guardian’s consent. If we become aware that we have unknowingly
      collected Personal Information from a child under the age of 13, we will
      make reasonable efforts to delete such information from our records. We
      encourage parents and legal guardians to be familiar with the Internet
      activity of their children.
    </p>
    <h4 className="text-xl">Changes to the Privacy Policy</h4>
    <p>
      We reserve the right to change this Privacy Policy. Such changes may be
      announced to you by the posting of the revised policy on the Service or by
      directly sending you a notification. We encourage you to review this
      Privacy Policy frequently to be informed of how we are protecting your
      information.
    </p>
    <h4 className="text-xl">About Us</h4>
    <p>
      Axeite is the platform that propels real connections.
      It’s the place that drives your target audience to recognize and remember your brand.
      The network to maximize your ad revenue. The perfect balance between creativity, engagement, and real revenue growth.
    </p>
    <h4 className="text-xl">Contact Us</h4>
    <p>
      Any questions, comments, and requests regarding this Privacy Policy are
      welcomed and should be addressed to {`me@axeite.com`}.
      Address: White Clay Center Dr, Newark, DE 19711, US
    </p>
  </div>
);
export default PrivacyPolicies;
